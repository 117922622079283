<template>
	<div class="main">
		<div class="top-box">
			<van-dropdown-menu>
				<van-dropdown-item v-model="queryPostData.msgType" @change="selectType" :options="option" />
				<van-dropdown-item v-model="queryPostData.status" @change="selectType" :options="optionStatus" />
			</van-dropdown-menu>
		</div>
		<div style="width: 100%;height: 50px"></div>
		<template  v-if="list.length > 0">
			<van-list
					v-model="loading"
					:finished="finished"
					:immediate-check="false"
					finished-text="没有更多信息了"
					class="list-main-all-box"
					@load="onLoad"
			>
				<div class="list-main-box" v-for="item in list" :key="item.id">
					<div class="info-main-box">
						<div class="info-box-top">
							<div class="info-box-title">
								<div class="info-box-title-icon"></div>
								<div>{{item.title}}</div>
							</div>
							<div class="info-line-main">
								<div v-html="item.description">
								</div>
							</div>
							<div v-if="item.pageUrl || item.msgType == 1" @click="toInfo(item)" class="info-box-item">
								<div>详情</div>
								<div><van-icon name="arrow" /></div>
							</div>
						</div>
					</div>
				</div>
			</van-list>
		</template>
		<template v-else>
			<empty description="暂无数据" />
		</template>
	</div>
</template>

<script>
	import { getNoticeList } from '@/api/service'
	import {Dialog, Tag, Toast} from 'vant';
	import { Empty } from 'vant';
	export default {
	  name: 'ExamineList',
	  components: {
		  Empty
	  },
	  data() {
	    return {
			list: [],
			loading: false,
			finished: false,
			refreshing: false,
			total:0,
			disableType:false,
			type:1,
			queryPostData: {
				msgType: '',
				status: 1,
				page: 1,
				pageSize: 20
			},
			option: [
				{ text: '全部消息', value: '' },
				{ text: '采购通知', value: 0 },
				{ text: '调拨通知', value: 1 },
				{ text: '提货通知', value: 2 },
				{ text: '退货通知', value: 3 },
				{ text: '卡转账通知', value: 4 },
				{ text: '换货通知', value: 6 },
				{ text: '红包充值', value: 7 },
				{ text: '主品销售价格审批', value: 9 },
				{ text: '市场销售政策审批', value: 10 },
			],
			optionStatus: [
				{ text: '未审核', value: 1 },
				{ text: '已审核', value: 2 },
			]
	    }},
	  created() {
		  const query = this.$route.query
		  console.log(query)
		  if(query.msgType){
		  	this.queryPostData.msgType = Number(query.msgType)
		  }
		  this.onLoad()
	  },
	  mounted:function(){
	  },
	  methods: {
		  onLoad() {
			  this.getData()
			  // 加载状态结束
			  this.loading = false;
			  this.refreshing = false;
		  },
		  selectType(){
			  this.list = []
			  this.queryPostData.page = 1
			  this.getData()
			  // 加载状态结束
			  this.loading = false;
			  this.refreshing = false;
		  },
		  // 获取数据
		  getData() {
			  if(this.disableType === true){
				  return false
			  }
			  this.disableType = true;
			  getNoticeList(this.queryPostData).then(response => {
				  this.disableType=false;
				  let nowPage=this.queryPostData.page
				  this.queryPostData.page=nowPage+1
				  for (let i = 0; i < response.data.list.length; i++) {
					  this.list.push(response.data.list[i]);
				  }
				  if(this.queryPostData.pageSize>response.data.list.length){
					  this.finished = true;
				  }
			  })
		  },
		  // 跳转到详情页
		  toInfo(item){
			  let param = {}
			  if(item.msgType == 2){
				  param = JSON.parse(item.pageParams)
				  this.$router.push({ path: '/approve', query: param });
			  }else if(item.msgType == 1){
				  param.allotNo = item.bizNo
				  this.$router.push({ path: '/warehouse', query: param });
			  }else if(item.msgType == 7){
				  param.allotNo = item.bizNo
				  this.$router.push({ path: '/red', query: param });
			  }else if(item.msgType == 9){
				  param.allotNo = item.bizNo
				  this.$router.push({ path: '/price', query: param });
			  }else if(item.msgType == 10){
				  param.allotNo = item.bizNo
				  this.$router.push({ path: '/strategy', query: param });
			  }else{
				  param = JSON.parse(item.pageParams)
				  this.$router.push({ path: item.pageUrl, query: param });
			  }
		  },
	  }
	}
</script>

<style scoped>
	.main{
		color:#333333;
	}
	.info-main-box{
		margin: 10px 10px 10px 10px;
	}
	.info-box-top{
		margin: 15px 0 0 0;
		border-radius: 5px;
		background-color: #ffffff;
		padding: 15px 15px 0 15px;
	}
	.info-box-title{
		display: flex;
		justify-content: flex-start;
		font-weight: bold;
	}
	.info-box-title-icon{
		width: 5px;
		height: 20px;
		background: #2586FF;
		color: #2586FF;
		margin-right: 10px;
	}
	.info-line-main{
		padding: 10px 0;
	}
	.info-box-line{
		height: 30px;
		line-height: 30px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-size: 16px;
	}
	.info-box-item{
		height: 50px;
		line-height: 50px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-size: 16px;
		border-top: 1px dashed #E5E5E5 ;
	}
	.top-box{
		position: fixed;
		top: 0;
		width: 100%;
		z-index: 3;
	}
</style>
